export const ASSIGNMENT_TARGET_STUDENT = 1;
export const ASSIGNMENT_TARGET_GROUP = 2;

export const ROLE_SYSTEM = 1;
export const ROLE_STAFF = 2;
export const ROLE_STUDENT = 3;

export const CONTENT_TYPE_ASSESSMENT = "assessment";
export const CONTENT_TYPE_ASSESSMENT_BANK = "assessment-bank";
export const CONTENT_TYPE_BLOCK = "block";
export const CONTENT_TYPE_CHAPTER = "chapter";
export const CONTENT_TYPE_COURSE = "course";
export const CONTENT_TYPE_ECHO = "echo";
export const CONTENT_TYPE_PAGE = "page";
export const CONTENT_TYPE_PAGESET = "pageset";
export const CONTENT_TYPE_SCAFFOLD = "scaffold";
export const CONTENT_TYPE_SECTION = "section";
export const CONTENT_TYPE_SUBSECTION = "subsection";
export const CONTENT_TYPE_UNIT = "unit";
export const CONTENT_TYPE_INTERACTIVE = "interactive";
export const CONTENT_TYPE_RUBRIC = "rubric";
export const CONTENT_TYPE_RUBRIC_CRITERIA = "rubric-criteria";
export const CONTENT_TYPE_SOURCE = "source";
export const CONTENT_TYPE_VOCABULARY = "vocab";

export const CONTENT_SUB_TYPE_STANDALONE = "standalone";

export const VOCAB_TERM_TYPE_SKILL = "Skill";

// For auto-remove, if a content is referenced at any level
// of the hierarchy by a content of one of these types, do not remove
export const ROOT_CONTENT_TYPES = [
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SOURCE
];

// eslint-disable-next-line prettier/prettier
export const SECTION_CONTENT_TYPES = [CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION];

// eslint-disable-next-line prettier/prettier
export const TOP_LEVEL_CONTENT_VIEWER_TYPES = [CONTENT_TYPE_CHAPTER, ...SECTION_CONTENT_TYPES];

export const isSubsectionLike = SECTION_CONTENT_TYPES.includes.bind(SECTION_CONTENT_TYPES);

export const CONTENT_TYPES_TO_AUTOREMOVE = [
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET
];

export const TERRITORY_SELECTION_TYPE_EVERYONE = 1;
export const TERRITORY_SELECTION_TYPE_INCLUDE = 2;
export const TERRITORY_SELECTION_TYPE_EXCLUDE = 3;

export const TERRITORY_OTHER = "XX";

export const TERRITORIES = [
  { name: "Alabama", id: "AL" },
  { name: "Alaska", id: "AK" },
  { name: "Arizona", id: "AZ" },
  { name: "Arkansas", id: "AR" },
  { name: "California", id: "CA" },
  { name: "Colorado", id: "CO" },
  { name: "Connecticut", id: "CT" },
  { name: "Delaware", id: "DE" },
  { name: "District Of Columbia", id: "DC" },
  { name: "Florida", id: "FL" },
  { name: "Georgia", id: "GA" },
  { name: "Hawaii", id: "HI" },
  { name: "Idaho", id: "ID" },
  { name: "Illinois", id: "IL" },
  { name: "Indiana", id: "IN" },
  { name: "Iowa", id: "IA" },
  { name: "Kansas", id: "KS" },
  { name: "Kentucky", id: "KY" },
  { name: "Louisiana", id: "LA" },
  { name: "Maine", id: "ME" },
  { name: "Maryland", id: "MD" },
  { name: "Massachusetts", id: "MA" },
  { name: "Michigan", id: "MI" },
  { name: "Minnesota", id: "MN" },
  { name: "Mississippi", id: "MS" },
  { name: "Missouri", id: "MO" },
  { name: "Montana", id: "MT" },
  { name: "Nebraska", id: "NE" },
  { name: "Nevada", id: "NV" },
  { name: "New Hampshire", id: "NH" },
  { name: "New Jersey", id: "NJ" },
  { name: "New Mexico", id: "NM" },
  { name: "New York", id: "NY" },
  { name: "North Carolina", id: "NC" },
  { name: "North Dakota", id: "ND" },
  { name: "Ohio", id: "OH" },
  { name: "Oklahoma", id: "OK" },
  { name: "Oregon", id: "OR" },
  { name: "Pennsylvania", id: "PA" },
  { name: "Rhode Island", id: "RI" },
  { name: "South Carolina", id: "SC" },
  { name: "South Dakota", id: "SD" },
  { name: "Tennessee", id: "TN" },
  { name: "Texas", id: "TX" },
  { name: "Utah", id: "UT" },
  { name: "Vermont", id: "VT" },
  { name: "Virginia", id: "VA" },
  { name: "Washington", id: "WA" },
  { name: "West Virginia", id: "WV" },
  { name: "Wisconsin", id: "WI" },
  { name: "Wyoming", id: "WY" },
  { name: "Other", id: TERRITORY_OTHER }
];

export const ECHO_FEATURE_ACT = "echo-act";
export const ECHO_FEATURE_BLASTY_BLAST = "echo-text";
export const ECHO_FEATURE_PICTURE_THIS = "echo-picture";
export const ECHO_FEATURE_POLL = "echo-poll";
export const ECHO_FEATURE_RATE = "echo-rate";
export const ECHO_FEATURE_TALK_BACK = "echo-audio";
export const ECHO_FEATURE_TOP_TEN = "echo-ranked";
export const isEchoFeature = (feature) => feature?.startsWith("echo-");

export const INTERACTIVE_TYPE_ANIMATOR = "animator";
export const INTERACTIVE_TYPE_AUDIO = "audio";
export const INTERACTIVE_TYPE_AUDIO_RECORDING = "audio-recording";
export const INTERACTIVE_TYPE_CHART = "chart";
export const INTERACTIVE_TYPE_CHART_COMPLETION = "chart-completion";
export const INTERACTIVE_TYPE_DGO_TABLE = "dgo-table";
export const INTERACTIVE_TYPE_DGO_TEMPORARY = "dgo"; // TODO: remove once scaffolds are done
export const INTERACTIVE_TYPE_DRAW_TOOL = "draw-tool";
export const INTERACTIVE_TYPE_ESSAY = "essay";
export const INTERACTIVE_TYPE_EVALUATION_RANKING = "evaluation-ranking";
export const INTERACTIVE_TYPE_FLIPBOOK = "flipbook";
export const INTERACTIVE_TYPE_FUN_FACTS = "fun-fact";
export const INTERACTIVE_TYPE_GROUP_AND_SORT = "group-and-sort";
export const INTERACTIVE_TYPE_HOTSPOT = "hotspot";
export const INTERACTIVE_TYPE_IMAGE = "image";
export const INTERACTIVE_TYPE_IMAGE_CLICKER = "image-clicker";
export const INTERACTIVE_TYPE_IMAGE_TITLE_DRAG = "image-title-drag";
export const INTERACTIVE_TYPE_IMAGE_ZOOMY = "image-zoomy";
export const INTERACTIVE_TYPE_LINEAR_SCALE = "linear-scale";
export const INTERACTIVE_TYPE_LINK = "link";
export const INTERACTIVE_TYPE_MULTIPLE_CHOICE = "multiple-choice";
export const INTERACTIVE_TYPE_PACING_GUIDE = "pacing-guide";
export const INTERACTIVE_TYPE_PLOT_DIAGRAM = "plot-diagram";
export const INTERACTIVE_TYPE_POLL = "poll";
export const INTERACTIVE_TYPE_ACTIVITY = "activity";
export const INTERACTIVE_TYPE_STACKED_SEQUENCE = "stacked-sequence";
export const INTERACTIVE_TYPE_TIMELINE = "timeline";
export const INTERACTIVE_TYPE_VENN_DIAGRAM = "venn-diagram";
export const INTERACTIVE_TYPE_VIDEO = "video";
export const INTERACTIVE_TYPE_WORD_CLOUD = "word-cloud";

export const INTERACTION_STATE_COMPLETED = "completed";
export const INTERACTION_STATE_TEACHER_SUBMITTING = "teacher-submitting";
export const INTERACTION_STATE_OPEN = "open";

export const LANGUAGES = [
  {
    id: "0",
    isoCode: "",
    name: "None"
  },
  {
    id: "5",
    isoCode: "es",
    name: "Spanish"
  }
];

export const LINKABLE_CONTENT_TYPES = [
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT
  /** TODO:
   * 'key term', 'skill','dbi'
   * 'dbi' is not its own content type, but a section/subsection with tabs
   * identified via tag possibly
   * SEE ALSO:
   *   frontend/src/apps/hss/views/Library/Search/Provider.js
   */
];

export const ABILITY_ADMINISTRATION_INTERFACE = "administration-interface";
export const ABILITY_ASSESSMENT_INTERFACE = "assessment-interface";
export const ABILITY_ASSIGNMENT_CREATION = "assignment-creation";
export const ABILITY_CONTENT_CREATION = "content-creation";
export const ABILITY_CONTENT_EDITING = "content-editing";
export const ABILITY_CONTENT_RESTRICTION = "content-restriction";
export const ABILITY_CURRICULUM_ACCESS = "curriculum-access";
export const ABILITY_DISTRICT_ADMINISTRATION = "district-administration";
export const ABILITY_LIBRARY_ACCESS = "library-access";
export const ABILITY_PREVIEW_AS_STUDENT = "preview-as-student";
export const ABILITY_RECEIVE_USER_ALERTS = "receive-user-alerts";
export const ABILITY_STUDENT_INTERFACE = "student-interface";
export const ABILITY_TEACHER_INTERFACE = "teacher-interface";

export const FEATURE_FLAG_GOOGLE_INTEGRATIONS = "googleIntegrations";
export const FEATURE_FLAG_GOOGLE_INTEGRATIONS_CLASSROOM = "googleIntegrationsClassroom";
export const FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS = "googleIntegrationsDocs";
export const FEATURE_FLAG_POST_MVP = "postMVP";
export const FEATURE_FLAG_SPANISH_CONTENT = "spanishContent";
export const FEATURE_FLAG_ADVANCED_FILTERS = "advancedFilters";

export const FEATURE_FLAG_CONTENT_CAROUSEL = "content-carousel";
export const FEATURE_FLAG_CUSTOMER_CONTENT = "customer-content";

export const ASSIGNABLE_FEATURE_FLAGS = [FEATURE_FLAG_CONTENT_CAROUSEL, FEATURE_FLAG_CUSTOMER_CONTENT];

export const PRIVILEGE_SUPER_ADMIN = "SUPER";
export const PRIVILEGE_CURRICULUM_MANAGER = "CURRICULUM_MANAGER";
export const PRIVILEGE_CONTENT_CREATOR = "CONTENT_CREATOR";
export const PRIVILEGE_CONTENT_EDITOR = "CONTENT_EDITOR";
export const PRIVILEGE_SCHOOL_ADMIN = "SCHOOL_ADMIN";
export const PRIVILEGE_DISTRICT_ADMIN = "DISTRICT_ADMIN";
export const PRIVILEGE_CUSTOMER_CONFIG = "CUSTOMER";
export const PRIVILEGE_REPORTING = "REPORTING";
export const PRIVILEGE_IMPERSONATE = "IMPERSONATE";
export const PRIVILEGE_RESEARCHER = "RESEARCHER";
export const PRIVILEGE_ASSESS = "ASSESSMENTS";
export const PRIVILEGE_HELP_CENTER = "HELP_CENTER";

export const SUB_PRIVILEGE_REPORTING = "reporting";
export const SUB_PRIVILEGE_CURRICULUM = "curriculum";

export const PRIVILEGES = [
  {
    id: PRIVILEGE_SUPER_ADMIN,
    description: "Can create and manage System Administrators, and inherits all other privileges",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_CURRICULUM_MANAGER,
    description: "Can create and modify all content",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_CONTENT_CREATOR,
    description: "Can create and modify all content except courses and units",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_CONTENT_EDITOR,
    description: "Can create all content except courses, units and chapters. Can modify chapters.",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_ASSESS,
    description: "Can create and modify assessments",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_HELP_CENTER,
    description: "Can create and modify help center content",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_CUSTOMER_CONFIG,
    description: "Can configure customer-specific options",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_REPORTING,
    description: "Can run and access reports",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_IMPERSONATE,
    description: "Can impersonate teachers and students for debugging purposes",
    roleId: ROLE_SYSTEM
  },
  {
    id: PRIVILEGE_SCHOOL_ADMIN,
    description: "School admin",
    roleId: ROLE_STAFF,
    config: {
      schoolId: "id",
      [SUB_PRIVILEGE_CURRICULUM]: "boolean",
      [SUB_PRIVILEGE_REPORTING]: "boolean"
    }
  },
  {
    id: PRIVILEGE_DISTRICT_ADMIN,
    description: "District admin",
    roleId: ROLE_STAFF,
    config: {
      districtId: "id",
      [SUB_PRIVILEGE_CURRICULUM]: "boolean",
      [SUB_PRIVILEGE_REPORTING]: "boolean"
    }
  },
  {
    id: PRIVILEGE_RESEARCHER,
    description: "Can run and access research reports",
    roleId: ROLE_SYSTEM
  }
];

export const PROFICIENCY_GROUPING_ABOVE_LEVEL = "Above Level";
export const PROFICIENCY_GROUPING_AT_LEVEL = "At Level";
export const PROFICIENCY_GROUPING_BELOW_LEVEL = "Below Level";
export const PROFICIENCY_GROUPING_ENGLISH_LEARNER = "English Learner";

export const PROFICIENCIES = [
  {
    id: "0",
    name: "None",
    grouping: PROFICIENCY_GROUPING_AT_LEVEL,
    assignableToContent: false
  },
  {
    id: "10",
    name: "Beginning",
    grouping: PROFICIENCY_GROUPING_ENGLISH_LEARNER,
    assignableToContent: true
  },
  {
    id: "20",
    name: "Intermediate",
    grouping: PROFICIENCY_GROUPING_ENGLISH_LEARNER,
    assignableToContent: true
  },
  {
    id: "30",
    name: "Advanced",
    grouping: PROFICIENCY_GROUPING_ENGLISH_LEARNER,
    assignableToContent: true
  },
  {
    id: "50",
    name: "Approaching",
    grouping: PROFICIENCY_GROUPING_BELOW_LEVEL,
    assignableToContent: true
  }
  //   TRAV-465 BEYOND may be added back later
  //   {
  //     id: "60",
  //     name: "Beyond",
  //     grouping: PROFICIENCY_GROUPING_ABOVE_LEVEL,
  //     assignableToContent: true
  //   }
];

export const USE_LEVELED_TEXT_FOR_ALL_STUDENTS = "all";
export const USE_LEVELED_TEXT_BY_PROFICIENCY = "by-proficiency"; // e.g. for English learners and below-level readers
export const USE_LEVELED_TEXT_OPTIONS = [USE_LEVELED_TEXT_FOR_ALL_STUDENTS, USE_LEVELED_TEXT_BY_PROFICIENCY];

export const SCORING_MODE_CHART_COLUMN = "chart-column";
export const SCORING_MODE_CHART_EXACT = "chart-exact";
export const SCORING_MODE_CHART_ROW = "chart-row";
export const SCORING_MODE_CHART_ROW_COMPLETE = "chart-row-complete";
export const SCORING_MODE_EVAL_RANKING_EXACT = "eval-ranking-exact";
export const SCORING_MODE_GNS_GROUP = "gns-group";
export const SCORING_MODE_GNS_GROUP_AND_SORT = "gns-group-and-sort";
export const SCORING_MODE_GNS_SORT_GIVEN_GROUPS = "gns-sort-given-groups";
export const SCORING_MODE_IMAGE_TITLE_DRAG = "image-title-drag";
export const SCORING_MODE_MULTICHOICE_ALL = "multichoice-all";
export const SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED = "multichoice-correct-weighted";
export const SCORING_MODE_MULTICHOICE_WEIGHTED = "multichoice-weighted";
export const SCORING_MODE_NONE = "none";
export const SCORING_MODE_RUBRIC = "rubric";

export const VALID_SCORING_MODES = [
  SCORING_MODE_CHART_COLUMN,
  SCORING_MODE_CHART_EXACT,
  SCORING_MODE_CHART_ROW,
  SCORING_MODE_CHART_ROW_COMPLETE,
  SCORING_MODE_EVAL_RANKING_EXACT,
  SCORING_MODE_GNS_GROUP,
  SCORING_MODE_GNS_GROUP_AND_SORT,
  SCORING_MODE_GNS_SORT_GIVEN_GROUPS,
  SCORING_MODE_IMAGE_TITLE_DRAG,
  SCORING_MODE_MULTICHOICE_ALL,
  SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED,
  SCORING_MODE_MULTICHOICE_WEIGHTED,
  SCORING_MODE_NONE,
  SCORING_MODE_RUBRIC
];

/* eslint-disable prettier/prettier */
export const SCORING_MODES = {
  // Echoes
  [ECHO_FEATURE_BLASTY_BLAST]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],
  [ECHO_FEATURE_PICTURE_THIS]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],
  [ECHO_FEATURE_TALK_BACK]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],

  // Interactives
  [INTERACTIVE_TYPE_AUDIO_RECORDING]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],
  [INTERACTIVE_TYPE_CHART_COMPLETION]: [
    SCORING_MODE_NONE,
    SCORING_MODE_CHART_EXACT,
    SCORING_MODE_CHART_COLUMN,
    SCORING_MODE_CHART_ROW,
    SCORING_MODE_CHART_ROW_COMPLETE
  ],
  [INTERACTIVE_TYPE_DRAW_TOOL]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],
  [INTERACTIVE_TYPE_ESSAY]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],
  [INTERACTIVE_TYPE_EVALUATION_RANKING]: [SCORING_MODE_NONE, SCORING_MODE_EVAL_RANKING_EXACT],
  [INTERACTIVE_TYPE_GROUP_AND_SORT]: [
    SCORING_MODE_NONE,
    SCORING_MODE_GNS_GROUP,
    SCORING_MODE_GNS_GROUP_AND_SORT,
    SCORING_MODE_GNS_SORT_GIVEN_GROUPS
  ],
  [INTERACTIVE_TYPE_IMAGE_TITLE_DRAG]: [SCORING_MODE_NONE, SCORING_MODE_IMAGE_TITLE_DRAG],
  [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: [
    SCORING_MODE_NONE,
    SCORING_MODE_MULTICHOICE_ALL,
    SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED,
    SCORING_MODE_MULTICHOICE_WEIGHTED
  ],
  [INTERACTIVE_TYPE_VENN_DIAGRAM]: [SCORING_MODE_NONE, SCORING_MODE_RUBRIC],
  [INTERACTIVE_TYPE_ACTIVITY]: [SCORING_MODE_RUBRIC]
};

export const REACTABLE_INTERACTIVES = [ECHO_FEATURE_BLASTY_BLAST, ECHO_FEATURE_PICTURE_THIS, ECHO_FEATURE_TALK_BACK];

export const COLLABORATIVE_INTERACTIVES = [
  ECHO_FEATURE_POLL,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_WORD_CLOUD,
  ECHO_FEATURE_TOP_TEN,
  // the following should probably be a different category to distinguish
  // additional business logic - ie review counts based on assignment
  ...REACTABLE_INTERACTIVES
];

export const MANUAL_SUBMITTABLE_INTERACTIVES = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_WORD_CLOUD
];

export const SCORING_RESULT_CORRECT = "CORRECT";
export const SCORING_RESULT_INCORRECT = "INCORRECT";
export const SCORING_RESULT_NEEDS_GRADING = "NEEDS_GRADING";

export const CONTENT_STATE_PUBLISHED = "PUBLISHED";
export const CONTENT_STATE_DRAFT = "DRAFT";

export const CONTENT_ORIGIN_CREATED_BY_SELF = "created-by-self";
export const CONTENT_ORIGIN_CREATED_BY_SYSTEM = "created-by-system";
export const CONTENT_ORIGIN_SHARED = "shared";

export const INTERACTION_TYPE_ANNOTATION = "annotation";
export const INTERACTION_TYPE_INTERACTIVE = "interactive";
export const INTERACTION_TYPE_NOTEBOOK = "notebook";
export const INTERACTION_TYPE_PAGE_VIEW = "page-view";
export const INTERACTION_TYPE_FEEDBACK = "teacher-notebook";
export const INTERACTION_TYPE_TEST_RESPONSES = "test-responses";

export const SCORING_FEEDBACK_CONTENT_ALL = "all";
export const SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS = "noExplanations";
export const SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES = "totalScoreWithResponses";
export const SCORING_FEEDBACK_CONTENT_TOTAL_SCORE = "totalScore";
export const SCORING_FEEDBACK_CONTENT_NONE = "nothing";

export const SCORING_FEEDBACK_VISIBILITY_IMMEDIATE = "immediate";
export const SCORING_FEEDBACK_VISIBILITY_AFTER_REVIEW = "afterPeerReview";
export const SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION = "dayAfter";
export const SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END = "afterEnd";

export const SCORING_FEEDBACK_CONTENT = [
  SCORING_FEEDBACK_CONTENT_ALL,
  SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE,
  SCORING_FEEDBACK_CONTENT_NONE
];
export const SCORING_FEEDBACK_VISIBILITY = [
  SCORING_FEEDBACK_VISIBILITY_IMMEDIATE,
  SCORING_FEEDBACK_VISIBILITY_AFTER_REVIEW,
  SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION,
  SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END
];

export const CONTENT_RESTRICTION_TYPE_CENSORED = "CENSORED";
export const CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT = "HIDDEN_BY_DEFAULT";

export const VALID_CONTENT_RESTRICTION_TYPES = [
  CONTENT_RESTRICTION_TYPE_CENSORED,
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT
];

export const AUTH_PROVIDER_IL_AUTH = "il";
export const AUTH_PROVIDER_IMLP = "imlp";
export const AUTH_PROVIDER_IMPERSONATE = "impersonate";
export const AUTH_PROVIDER_OKTA = "okta";

// Alerts
export const ALERT_MODE_INTERRUPT = "interrupt";
export const ALERT_MODE_PROFILE = "profile";
export const ALERT_TYPE_STUDENT = "student";
export const ALERT_TYPE_SYSTEM = "system";
export const ALERT_TYPE_TEACHER = "teacher";

// Backend-specific
export const SESSION_TYPE_UNPW = 1;
export const SESSION_TYPE_IL = 2;
